import React from "react";
import * as ReactRouter from "react-router-dom";

import "./Navbar.css";
import ListIcon from "@mui/icons-material/List";

import { ReactComponent as ArtyPolicySVG } from "./artypk.svg";

import {
    Box,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    SvgIcon,
} from "@mui/material";
import { LogoutButton } from "./LogoutButton";

const ArrowRightSVG = "/images/pil4.svg";
const SettingsSVG = "/images/settingsinv.svg";

export const PolicyNavbar = (props) => {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    function MenuIcon(props) {
        return (
            <SvgIcon
                className="nav-icon-2"
                {...props}
                fontSize="large"
                component={ListIcon}
            />
        );
    }

    return (
        <div className="policy-navbar">
            <div className="nav-container">
                <ReactRouter.Link to={"/policy/articles"} style={{ padding: 0 }}>
                    <ArtyPolicySVG
                        className={"nav-icon-1"}
                        style={
                            open ? { filter: "invert(100%)", transition: "'opacity' 325ms 'ease'" } 
                                : { filter: "invert(0%)", transition: "325ms" }
                        }
                        width={150}
                    />
                </ReactRouter.Link>
                <MenuIcon onClick={() => toggleDrawer()} />
            </div>
            <Drawer
                anchor={"left"}
                open={open}
                ModalProps={{ onClose: () => setOpen(false) }}
                BackdropProps={{ invisible: true }}
                transitionDuration={600}
                elevation={1}
                sx={{ zIndex: 1 }}
            >
                <MenuDrawer setOpen={setOpen}/>
            </Drawer>
        </div>
    );
};

function MenuDrawer({setOpen}) {
    const DrawerItem = ({text, image, navLinkDest}) => {
        return (
            <ListItem component={ReactRouter.NavLink} to={navLinkDest} className="list-item" disablePadding>
                <ListItemButton onClick={() => { setOpen(false) }}>
                        <ListItemIcon sx={{ width: "15px", minWidth: "15px" }}>
                            <img src={image} width={15} height={15} alt={text} />
                        </ListItemIcon>
                        <ListItemText className="policy-list-item-text" disableTypography>
                            {text}
                        </ListItemText>
                </ListItemButton>
            </ListItem>
        );
    }

    return (
        <Box component="div" className="nav-menu">
            <nav aria-label="main pages">
                <List disablePadding>
                    <DrawerItem text="ARTY Hälsa" image={ArrowRightSVG} navLinkDest="/app/media" />
                    <DrawerItem text="ARTY Policy" image={ArrowRightSVG} navLinkDest="/policy" />
                </List>
            </nav>
            <Divider className="policy-navbar-divider" />
            <nav aria-label="account">
                <List disablePadding>
                    <DrawerItem text="Inställningar" image={SettingsSVG} navLinkDest="/app/profile/" />
                    <LogoutButton />
                </List>
            </nav>
        </Box>
    );
}

