import React from "react";

import ArtyArticle from "./ArtyArticle";

import * as ArtyApi from "../ArtyApi.js";
import { Box, CircularProgress } from "@mui/material";

export default class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            item: null,
            id: props.match.params.id,
        };
    }
    componentDidMount() {
        return ArtyApi.article(this.state.id).then((item) => {
            this.setState({ item });
        });
    }

    render() {
        if (!this.state.item) {
            return (
                <Box sx={{ display: "flex", height: "363px", alignItems: "center" }}>
                    <CircularProgress className="circular-progress-div" />
                </Box>
            );
        }

        return (
            <div className="arty-article-view">
                <div className="article-wrapper">
                    <ArtyArticle item={this.state.item.data[0]} />
                </div>
            </div>
        );
    }
}
