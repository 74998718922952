import "./App.css";
import "@fontsource/dm-sans";

import React, { useEffect, useState } from "react";

import * as ArtyApi from "./ArtyApi.js";
import ArtyNotif from "./views/ArtyNotif";
import { ArtyLoginView } from "./views/ArtyLoginView";
import ArtyLandingView from "./views/ArtyLandingView";
import ArtyShellView from "./views/ArtyShellView";
import ArtyPolicyShellView from "./views/ArtyPolicyShellView";
import * as ReactRouter from "react-router-dom";

export default function App() {
    clearCache();
    ArtyApi.init(process.env.REACT_APP_API_URL);

    return (
        <React.Fragment>
            <ArtyNotif />
            <ReactRouter.BrowserRouter>
                <React.Fragment>
                    <ReactRouter.Route exact path="/" component={ArtyLandingView} />
                    <ReactRouter.Route path="/login" component={ArtyLoginView} />
                    <PrivRoute path="/policy" component={ArtyPolicyShellView} />
                    <PrivRoute path="/app" component={ArtyShellView} />
                </React.Fragment>
            </ReactRouter.BrowserRouter>
        </React.Fragment>
    );
}

// Internals
function PrivRoute({ component: Component, ...rest }) {
    const [hasSession, setHasSession] = useState(false);

    // Forces change of authentication method
    useEffect(() => {
        (async () => {
            if (
                localStorage.getItem("ARTY_ACCESS_TOKEN") &&
                !localStorage.getItem("session")
            )
                await ArtyApi.session();
            setHasSession(true);
        })();
    }, []);

    if (!hasSession) return <></>;

    return (
        <ReactRouter.Route
            {...rest}
            render={(props) =>
                ArtyApi.isAuthorized() ? (
                    <Component {...props} />
                ) : (
                    <ReactRouter.Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
}

function clearCache() {
    fetch("/meta.json")
        .then((data) => data.json())
        .then((data) => {
            const refreshDate = data.buildDate;
            const cachedBuildDate = localStorage.getItem("buildDate");
            if (!cachedBuildDate || refreshDate > cachedBuildDate) {
                localStorage.setItem("buildDate", refreshDate);
                refreshCacheAndReload();
            }
        })
        .catch((_) => {});
}

const refreshCacheAndReload = () => {
    if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
            for (const name of names) {
                caches.delete(name);
            }
        });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
};
