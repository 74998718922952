import React from "react";

import ReactInfiniteScroll from "react-infinite-scroll-component";

import ArtyArticle from "../../views/ArtyArticle";

export const CoverageList = (props) => {
    const getArticles = () => {
        return props.items.map((item) => {
            let link = `/app/article/${item.id}/`;

            return <ArtyArticle  key={item.id} item={item} linkTo={link} />;
        });
    };

    return (
        <div className="article-list">
            <ReactInfiniteScroll
                className="infinite-scroll"
                dataLength={props.items.length}
                next={() => props.fetchData()}
                hasMore={props.hasMore}
                loader={<h4 className="loading-msg">Laddar fler artiklar...</h4>}
                endMessage={
                    <p style={{ textAlign: "center", padding: "60px 0" }}>
                        <b>Inga fler artiklar i listan</b>
                    </p>
                }
                scrollableTarget="arty-list-view"
            >
                {getArticles()}
            </ReactInfiniteScroll>
        </div>
    );
};
