import ArtyListView from "./ArtyListView";

import * as ArtyApi from "../ArtyApi.js";

export default class extends ArtyListView {
    constructor(props) {
        super(props);
        this.state.hasMore = true;
        this.state.hasNewItems = true;
        this.state.filter = "media";
    }

    fetchPromoted = async () => {
        const promtedArticles = await ArtyApi.promoteList("ARTICLE");

        this.setState({ promotedItems: promtedArticles });
    };

    fetchMoreData = async (
        items = this.state.items,
        filter = this.state.filter,
        offset = this.state.listOffset,
        size = this.state.listSize
    ) => {
        const promtedArticles = await ArtyApi.promoteList("ARTICLE");
        if (this.state.hasNewItems) {
            ArtyApi.mediaUnseenList(offset, size).then(async (result) => {
                let hasMore = result.data.length >= 10;

                let listOffset = offset + size;
                let firstflag = false;
                if (result.data.length === 0) {
                    result = await ArtyApi.mediaList(offset, size);
                    hasMore = false;
                    firstflag = true;
                    listOffset = size;
                }
                let sepItem = [];

                if (hasMore === false) {
                    sepItem = sepItem.concat([
                        {
                            id: "--no new items seperator--",
                            isNewItemsSeperator: true,
                        },
                    ]);
                    listOffset = firstflag ? size : 0;
                }

                this.setState({
                    promotedItems: promtedArticles,
                    filter: filter,
                    items: !firstflag
                        ? items.concat(
                              removeExistingItems(result.data, items).concat(sepItem)
                          )
                        : items.concat(
                              sepItem.concat(removeExistingItems(result.data, items))
                          ),
                    listOffset: listOffset,
                    hasNewItems: hasMore,
                });
            });
        } else
            ArtyApi.mediaList(offset, size).then((result) => {
                this.setState({
                    promotedItems: promtedArticles,
                    filter: filter,
                    items: [...items.concat(removeExistingItems(result.data, items))],
                    listOffset: offset + size,
                    hasNewItems: false,
                    hasMore: result.data.length !== 0,
                });
            });
    };
}

function removeExistingItems(newItems, existingItems) {
    return newItems.filter((item) => {
        return !existingItems.some((item2) => item2.id === item.id);
    });
}
