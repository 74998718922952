import "./ArtyProtocolView.css";
import ArtySearchView from "./ArtySearchView";
import { protocolSearchConfig } from "./protocol/searchConfig";

export default class extends ArtySearchView {
    constructor(props) {
        console.log(props);
        super(props);
        this.state.filter = protocolSearchConfig;
    }
}
