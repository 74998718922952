import "./LoginForm.css";

import React, { useState } from "react";

import * as ArtyApi from "../../ArtyApi.js";

import { AppBar, Box, Button, Grid, Toolbar } from "@mui/material";

const HealthImagePath = "/images/logo-arty-health.svg";
const PolicyImagePath = "/images/logo-arty-policy.svg";
const HealthBackgroundImagePath = "/images/background-arty-health-1600.jpeg";
const PolicyBackgroundImagePath = "/images/background-arty-policy-1600.jpeg";
const HealthCss = "login-page-health";
const PolicyCss = "login-page-policy";

export const LoginForm = ({ redirectToReferrer, setRedirectTarget, redirectTarget }) => {
    const [thisError, setThisError] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        let licenses = [];
        try {
            await ArtyApi.login(event.target.username.value, event.target.password.value);
            const session = await ArtyApi.session();
            licenses = session.data.user.organisations[0].licenses.map(
                (license) => license.name
            );
            licenses = licenses.concat(
                session.data.user.licenses.map((license) => license.name)
            );
        } catch (error) {
            return setThisError(error.message);
        }

        if (!licenses.includes("policy") && redirectTarget === "/policy") {
            setThisError("Detta konto har inte rättigheter till ARTY Policy");
        } else {
            redirectToReferrer(true);
        }
    };

    const Notification = ({ error }) => {
        return error ? <p>{error}</p> : null;
    };

    const [imagePath, setImagePath] = useState(HealthImagePath);
    const [BackgroundImagePath, setBackgroundImagePath] = useState(
        HealthBackgroundImagePath
    );
    const [Css, setCss] = useState(HealthCss);

    const ChangeTabPolicy = () => {
        setImagePath(PolicyImagePath);
        setBackgroundImagePath(PolicyBackgroundImagePath);
        setCss(PolicyCss);
        setRedirectTarget("/policy");
    };

    const ChangeTabHealth = () => {
        setImagePath(HealthImagePath);
        setBackgroundImagePath(HealthBackgroundImagePath);
        setCss(HealthCss);
        setRedirectTarget("/");
    };

    const LoginForm = (imagePath) => {
        return (
            <form className="form" onSubmit={(e) => handleSubmit(e)}>
                <legend>
                    <Box
                        component="img"
                        sx={{
                            width: 300,
                            height: 300,
                        }}
                        alt="Arty"
                        src={imagePath}
                    />
                </legend>
                <Notification error={thisError} />
                <input
                    type="email"
                    id="username"
                    name="username"
                    placeholder="Användarnamn (epostadress)"
                    autoComplete="username"
                />
                <input
                    type="password"
                    id="password"
                    name="Lösenord"
                    placeholder="Lösenord"
                />
                <button className="button">Logga in</button>
            </form>
        );
    };

    const backgroundImage = (BackgroundImagePath) => {
        return (
            <Box
                className="background-image"
                component="img"
                alt="Arty"
                src={BackgroundImagePath}
            />
        );
    };

    return (
        <Box sx={{ flexGrow: 1 }} className={Css}>
            <AppBar className="login-nav-bar">
                <Toolbar style={{ backgroundColor: "#FFFFFF" }}>
                    <Button onClick={ChangeTabHealth} className="health-button">
                        Arty Hälsa
                    </Button>
                    <Button onClick={ChangeTabPolicy} className="policy-button">
                        Arty Policy
                    </Button>
                </Toolbar>
            </AppBar>
            <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                <Grid container spacing={2}>
                    <Grid className="center" item xs={4}>
                        {LoginForm(imagePath)}
                    </Grid>
                    <Grid item xs={8}>
                        {backgroundImage(BackgroundImagePath)}
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
                {LoginForm(imagePath)}
            </Box>
        </Box>
    );
};
