import React from "react";

import * as ArtyApi from "../../ArtyApi.js";

import * as ArtyNotif from "../../views/ArtyNotif";

export const ChangePasswordForm = (props) => {
    const handleValidatePassword = (event) => {
        const form = event.target.closest("form");
        const password = form.querySelector("#password");
        const confirmPassword = form.querySelector("#confirm_password");

        if (password.value !== confirmPassword.value) {
            confirmPassword.setCustomValidity("Lösenorden matchar inte");
        } else {
            confirmPassword.setCustomValidity("");
        }
    };

    const handleChangePasswordSubmit = async (event) => {
        event.preventDefault();
        const password = event.target.querySelector("#password");

        await ArtyApi.changePassword(password.value, props.id);

        ArtyNotif.notif("Ditt lösenord är nu uppdaterat");
    };

    return (
        <form onSubmit={(e) => handleChangePasswordSubmit(e)} className="input-form">
            <input
                type="text"
                name="username"
                value={ArtyApi.username()}
                autoComplete="username email"
                readOnly
                style={{ display: "none" }}
            />
            <fieldset>
                <legend>Byt Lösenord</legend>
                <input
                    type="password"
                    name="password"
                    id="password"
                    autoComplete="new-password"
                    placeholder="Nytt lösenord"
                    onChange={(e) => handleValidatePassword(e)}
                    required
                />
                <input
                    type="password"
                    name="confirm_password"
                    id="confirm_password"
                    autoComplete="new-password"
                    placeholder="Bekräfta nytt lösenord"
                    onKeyUp={(e) => handleValidatePassword(e)}
                    required
                />
                <button type="submit">Uppdatera lösenord</button>
            </fieldset>
        </form>
    );
};
