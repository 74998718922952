import React from 'react';

const BannerComponent = () => {
  return (
    <div style={{ 
      backgroundColor: '#f2f2f2', 
      padding: '10px', 
      border: '1px solid #cccccc', 
      fontFamily: 'Arial', 
      fontSize: '12px', 
      color: 'black', 
      fontWeight: 'bold' 
    }}>
      Du kan söka exakta ord eller ordkombinationer genom att använda citationstecken, till exempel "Arty AI"
    </div>
  );
};

export default BannerComponent;