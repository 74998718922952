import React from "react";

import * as ArtyApi from "../../ArtyApi.js";

export const CheckboxItem = ({ item }) => {
    const checked = item.active ? "checked" : "";

    function handleChange(event) {
        ArtyApi.profileToggleSetting(
            { id: item.id, active: event.target.checked },
            item.id
        )
            .then(() => {})
            .catch((error) => {
                console.error(error.stack);
            });
    }

    return (
        <li key={item.id}>
            <label className="fancy-toggle-switch">
                <input
                    type="checkbox"
                    onChange={(e) => handleChange(e)}
                    value={item}
                    defaultChecked={checked}
                />
                <span className="switcher"></span>
                <span className="label">{item.name}</span>
            </label>
        </li>
    );
};
