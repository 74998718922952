import "./SourcesForm.css";
import React, { useState, useEffect } from "react";

import { CheckboxItem } from "./CheckboxItem";
import { Box, CircularProgress } from "@mui/material";

export const SourcesForm = (props) => {
    const [items, setItems] = useState(null);

    useEffect(() => {
        if (props.items) {
            const checkBoxItems = props.items
                .filter((item) => item.name.toLowerCase().includes(""))
                .sort((item1, item2) => item1.name.trim().localeCompare(item2.name.trim()))
                .map((item) => {
                    return <CheckboxItem item={item} />;
                });

            setItems(checkBoxItems);
        }
    }, [props.items, props.group]);

    function createAndSetCheckBoxItems(filter) {
        const checkBoxItems = props.items
            .filter((item) => item.name.toLowerCase().includes(filter))
            .sort((item1, item2) => item1.name.trim().localeCompare(item2.name.trim()))
            .map((item) => {
                return <CheckboxItem item={item} />;
            });

        setItems(checkBoxItems);
    }

    const handleFilterChanged = (event) => {
        const filter = event.target.value.toLowerCase().trim();
        createAndSetCheckBoxItems(filter);
    };

    return (
        // Active source packages

        <form>
            <fieldset>
                <legend>{props.title}</legend>
                <div className="filter-input">
                    <input
                        type="search"
                        placeholder="Filtrera"
                        onKeyUp={(e) => handleFilterChanged(e)}
                    />
                </div>
                {items ? (
                    <ul className="checkbox-list">{items}</ul>
                ) : (
                    <Box sx={{ display: "flex", height: "200px", alignItems: "center" }}>
                        <CircularProgress className="circular-progress-div" />
                    </Box>
                )}
            </fieldset>
        </form>
    );
};
