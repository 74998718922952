export { init };
export { isUser };
export { isSuperUser };
export { isAdmin };
export { userid };
export { group };

const State = {
    session: null,
};

function init(session) {
    State.session = session;
}

function isUser() {
    return State.session ? State.session.role === "user" : false;
}

function isSuperUser() {
    return State.session ? State.session.data.user.role === "ADMIN" : false;
}

function isAdmin() {
    return isSuperUser() || State.session ? State.session.role === "admin" : false;
}

function userid() {
    return State.session ? State.session.id : null;
}

function group() {
    return State.session ? State.session.group : null;
}
