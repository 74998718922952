import React from "react";
import "./ArtyPolicyShellView.css";

import * as ArtyApi from "../ArtyApi.js";
import * as ArtySession from "../ArtySession.js";
import * as ReactRouter from "react-router-dom";

import ArtyPolicyView from "./ArtyPolicyView";
import ArtyPolicyDetailsView from "./ArtyPolicyDetailsView";
import { PolicyNavbar } from "../components/Policy/Navbar.jsx";

export default class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            session: null,
            licenses: [],
            sessionError: false,
            root: props.match.path,
        };
    }

    async componentDidMount() {
        try {
            const session = await ArtyApi.session();
            ArtySession.init(session);
            let licenses = session.data.user.organisations[0].licenses.map(
                (license) => license.name
            );
            licenses = licenses.concat(
                session.data.user.licenses.map((license) => license.name)
            );

            this.setState({ session, licenses: licenses });
        } catch (error) {
            this.setState({
                sessionError: true,
            });
        }
    }

    render() {
        if (this.state.sessionError) {
            return <ReactRouter.Redirect to="/login" />;
        }

        if (this.state.session === null) {
            return "";
        }

        if (!this.state.licenses.includes("POLICY")) {
            return <ReactRouter.Redirect to="/app" />;
        }

        return (
            <div id="policy-shell-view" className="policy-shell-view">
                <PolicyNavbar />
                <div id="policy-shell-content" className="policy-shell-content">
                    <ReactRouter.Route exact path="/policy">
                        <ReactRouter.Redirect to="/policy/articles" />
                    </ReactRouter.Route>
                    <ReactRouter.Route path="/policy/articles/:id/">
                        <ArtyPolicyDetailsView />
                    </ReactRouter.Route>
                    <ReactRouter.Route exact path="/policy/articles">
                        <ArtyPolicyView />
                    </ReactRouter.Route>
                </div>
            </div>
        );
    }
}
