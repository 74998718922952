import "../promoteList.css";

import React from "react";

import * as ReactRouter from "react-router-dom";

export default class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: props.items,
            group: props.group,
        };
    }
    "";

    render() {
        if (this.state.group === "social" || this.state.group === "protocol") {
            return "";
        }

        if (this.props.items.length === 0) {
            return (
                <section className="arty-promote-list">
                    <h4>
                        Det finns inga favoriter just nu. Stjärn-markera artiklar, för att
                        visas här.
                    </h4>
                </section>
            );
        }

        const list = this.props.items.map((item) => {
            const link = `/app/article/${item.id}`;
            return (
                <PromotedArticle
                    key={item.id}
                    time={item.publishedAt}
                    linkTo={link}
                    text={item.title || item.text}
                    image={item.image}
                    group={this.state.group}
                />
            );
        });

        return (
            <section className="arty-promote-list">
                <div className="inner">{list}</div>
            </section>
        );
    }

    // Handlers
}

// Internals

function PromotedArticle(props) {
    const date = new Date(props.time);
    const year = date.toLocaleString("default", { year: "numeric" });
    const month = date.toLocaleString("default", { month: "short" }).toLowerCase();
    const day = date.toLocaleString("default", { day: "2-digit" });
    const datestr = `${year}-${month}-${day}`;

    let articleClass = "promoted-article";

    if (props.group === "decision") {
        articleClass += " decision";
    }

    return (
        <ReactRouter.Link to={props.linkTo} className={articleClass}>
            <span className="date">{datestr}</span>
            <h3>{props.text}</h3>
        </ReactRouter.Link>
    );
}
