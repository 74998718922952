import dayjs from "dayjs";

export const staticSourceList = [
    {
        name: "Regeringen",
        value: ["Regeringen", "Regeringskansliet", "Socialdepartementet"],
    },
    {
        name: "Riksdagen",
        value: ["Riksdagen"],
    },
    {
        name: "Myndigheter",
        value: [
            "eHälsomyndigheten",
            "Folkhälsomyndigheten (FHM)",
            "Forskningsrådet för hälsa, arbetsliv och välfärd (Forte)",
            "Hälso- och sjukvårdens ansvarsnämnd (HSAN)",
            "Inspektionen för socialförsäkringen (ISF)",
            "Inspektionen för vård och omsorg (IVO)",
            "Läkemedelsverket (LMV)",
            "Myndigheten för vård- och omsorgsanalys",
            "Socialstyrelsen",
            "Statens beredning för medicinsk och social utvärdering (SBU)",
            "Tandvårds- och läkemedelsförmånsverket (TLV)",
            "Stiftelsen WHO Collaborating Centre for International Drug Monitoring",
            "Vetenskapsrådet",
        ],
    },
    {
        name: "Regioner",
        value: [
            "Region Stockholm",
            "Västra Götalandsregionen",
            "Region Skåne",
            "Region Blekinge",
            "Region Dalarna",
            "Region Gotland",
            "Region Gävleborg",
            "Region Halland",
            "Region Jämtland Härjedalen",
            "Region Jönköpings län",
            "Region Kalmar",
            "Region Kronoberg",
            "Region Norrbotten",
            "Region Sörmland",
            "Region Uppsala",
            "Region Värmland",
            "Region Västerbotten",
            "Region Västmanland",
            "Region Västernorrland",
            "Region Östergötland",
            "Region Örebro län",
            "SKR - Sveriges Kommuner och Regioner",
        ],
    },
];

export const SourceList = [
    "Västra Götalandsregionen",
    "Region Skåne",
    "Region Uppsala",
    "Region Stockholm",
    "Region Värmland",
    "Region Jämtland Härjedalen",
    "Region Västerbotten",
    "Region Kronoberg",
    "Region Västernorrland",
    "Region Halland",
    "Socialdepartementet",
    "Region Östergötland",
    "Region Blekinge",
    "Region Sörmland",
    "Region Västmanland",
    "Region Norrbotten",
    "Region Dalarna",
    "Regeringen",
    "Region Kalmar",
    "Riksdagen",
    "Region Jönköpings län",
    "Region Örebro län",
    "Region Gotland",
    "Socialstyrelsen",
    "SKR - Sveriges Kommuner och Regioner",
    "Folkhälsomyndigheten",
    "Region Gävleborg",
    "eHälsomyndigheten",
    "Statens beredning för medicinsk utvärdering",
    "SKL - Sveriges Kommuner och Landsting",
    "Forskningsrådet för hälsa, arbetsliv och välfärd",
    "Inspektionen för vård och omsorg",
    "Läkemedelsverket",
    "Myndigheten för vård- och omsorgsanalys",
    "Läkemedelsverket (LMV)",
    "Forskningsrådet för hälsa, arbetsliv och välfärd (Forte)",
    "Folkhälsomyndigheten (FHM)",
    "Myndigheten för Vårdanalys",
    "Statens beredning för medicinsk och social utvärdering (SBU)",
    "Regeringskansliet",
    "Inspektionen för vård och omsorg (IVO)",
    "Vetenskapsrådet",
];

const today = dayjs().valueOf();
const threeMonthAgo = dayjs().subtract(1, "month").valueOf();

export const searchConfig = {
    includedContentTypes: ["media", "decision", "protocol"],
    searchPhrase: "",
    size: 100,
    offset: 0,
    filters: [
        {
            fieldName: "date",
            filterType: "gte",
            filterValues: threeMonthAgo,
        },
        {
            fieldName: "date",
            filterType: "lte",
            filterValues: today,
        },
        {
            fieldName: "userId",
            filterType: "String match",
            filterValues: localStorage.getItem("ARTY_USER_TOKEN"),
        },
        {
            fieldName: "groups",
            filterType: "String match",
            filterValues: ["media", "protocol", "decision"],
        },
    ],
    sortOrder: "title",
};

export const protocolSearchConfig = {
    includedContentTypes: ["protocol"],
    searchPhrase: "",
    size: 100,
    offset: 0,
    filters: [
        {
            fieldName: "date",
            filterType: "gte",
            filterValues: threeMonthAgo,
        },
        {
            fieldName: "date",
            filterType: "lte",
            filterValues: today,
        },
        {
            fieldName: "sources",
            filterType: "String match",
            filterValues: staticSourceList,
        },
        {
            fieldName: "userId",
            filterType: "String match",
            filterValues: localStorage.getItem("ARTY_USER_TOKEN"),
        },
    ],
    sortOrder: "title",
};
