// import "./Coverage.css";

import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BannerComponent from '../BannerComponent';
import {
    Dialog,
    Button,
    DialogActions,
    DialogTitle,
    DialogContent,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Switch,
    TextField,
} from "@mui/material";
import { useDebouncedCallback } from "use-debounce";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import * as ArtyApi from "../../ArtyApi";
import { MobileDatePicker } from "@mui/x-date-pickers";

export const CoverageFilter = ({ setData, onLoading }) => {
    const [startDate, setStartDate] = useState(dayjs().subtract(1, "month").valueOf());
    const [endDate, setEndDate] = useState(dayjs().valueOf());

    const [sortOrder, setSortOrder] = useState("STRING_MATCH");

    const [searchPhrase, setSearchPhrase] = useState("");

    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [sourceSelector, setSourceSelector] = useState("ALL");
    const [filterValue, setFilterValue] = React.useState({});

    const handleSourceChange = (source) => {
        switch (source) {
            case "ALL":
                setSourceSelector("ALL");
                break;
            case "ARTICLE":
                setSourceSelector("ARTICLE");
                break;
            case "PROTOCOL":
                setSourceSelector("PROTOCOL");
                break;
            case "DECISION":
                setSourceSelector("DECISION");
                break;
            default:
                setSourceSelector("ALL");
                break;
        }
    };

    const changeSearchType = (active) => {
        setAdvancedSearch(active);
    };

    // find the parent component that defines it and wrap that definition in useCallback

    const debounce = useDebouncedCallback(async () => {
        const filter = queryBuilder(
            startDate,
            endDate,
            searchPhrase,
            sortOrder,
            sourceSelector
        );
        onLoading(true);
        const items = await ArtyApi.genericSearch(filter);
        setData(items.data);
        onLoading(false);
    }, 200);

    useEffect(() => {
        debounce();
    }, [debounce, searchPhrase, startDate, endDate, sortOrder, sourceSelector]);

    const SourceSelector = () => {
        return (
            <FormControl>
                <InputLabel>Välj källa</InputLabel>
                <Select
                    label={"Sortering"}
                    value={sourceSelector}
                    onChange={(event) => {
                        handleSourceChange(event.target.value);
                    }}
                >
                    <MenuItem value={"ALL"}>Alla</MenuItem>
                    <MenuItem value={"PROTOCOL"}>Protokoll</MenuItem>
                    <MenuItem value={"DECISION"}>Beslut</MenuItem>
                    <MenuItem value={"ARTICLE"}>Media</MenuItem>
                </Select>
            </FormControl>
        );
    };

    const [renderDesktop, setRenderDesktop] = useState(true);
    useEffect(() => {
        const handleResize = () => {
            setRenderDesktop(window.innerWidth >= 600);
        };
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const DatePicker = () => {
        return renderDesktop ? (
            <LocalizationProvider adapterLocale="sv" dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    className="date-picker"
                    label="Startdatum"
                    inputFormat="YYYY-MM-DD"
                    value={startDate}
                    onChange={(newValue) => {
                        setStartDate(newValue.valueOf());
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
                <DesktopDatePicker
                    className="date-picker"
                    label="Slutdatum"
                    inputFormat="YYYY-MM-DD"
                    value={endDate}
                    onChange={(newValue) => {
                        setEndDate(newValue.valueOf());
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        ) : (
            <LocalizationProvider adapterLocale="sv" dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                    className="date-picker"
                    label="Startdatum"
                    inputFormat="YY-MM-DD"
                    value={startDate}
                    onChange={(newValue) => {
                        setStartDate(newValue.valueOf());
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
                <MobileDatePicker
                    className="date-picker"
                    label="Slutdatum"
                    inputFormat="YY-MM-DD"
                    value={endDate}
                    onChange={(newValue) => {
                        setEndDate(newValue.valueOf());
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        );
    };

    const LoadFilter = () => {
        const [open, setOpen] = React.useState(false);
        const [filterName, setFilterName] = React.useState("");
        const [filters, setFilters] = React.useState(["All"]);
        const handleClickOpen = () => {
            setOpen(true);
            let filters = JSON.parse(localStorage.getItem("ArtyCoverageFilter"));
            if (filters === null) {
                filters = [];
            }
            setFilters(filters);
        };
        const handleClose = () => {
            setOpen(false);
        };
        const handleLoad = async () => {
            let filter = filters.filter((filter) => filter.name === filterName);
            if (filter.length > 0) {
            }
            setEndDate(filter[0].value.query.date.to);
            setStartDate(filter[0].value.query.date.from);
            setSearchPhrase(filter[0].value.query.text);
            setSortOrder(filter[0].value.orderBy.field);
            setSourceSelector(filter[0].value.query.type);
            setOpen(false);
        };

        const handleRemove = () => {
            let filters = JSON.parse(localStorage.getItem("ArtyCoverageFilter")).filter(
                (filter) => filter.name !== filterName
            );
            localStorage.setItem("ArtyCoverageFilter", JSON.stringify(filters));
            setFilters(filters);
            setOpen(false);
        };

        return (
            <div>
                <Button
                    className="filter-loader"
                    variant="outlined"
                    onClick={handleClickOpen}
                >
                    Sparade sökningar
                </Button>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Mina sparade sökningar</DialogTitle>
                    <DialogContent>
                        <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <InputLabel>Sökningar</InputLabel>
                            <Select
                                label="Sökningar"
                                value={filterName}
                                onChange={(event) => {
                                    setFilterName(event.target.value);
                                }}
                                inputProps={{
                                    name: "filterName",
                                    id: "filterName",
                                }}
                            >
                                {filters.map((filter) => (
                                    <MenuItem key={filter.name} value={filter.name}>
                                        {filter.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleLoad}>Sök</Button>
                        <Button onClick={handleClose}>Avbryt</Button>
                        <Button onClick={handleRemove}>Ta bort</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    const SaveFilter = () => {
        const [open, setOpen] = React.useState(false);
        const [filterName, setFilterName] = React.useState("");
        const handleClickOpen = () => {
            setOpen(true);
        };
        const handleClose = () => {
            setOpen(false);
        };
        const handleSave = () => {
            let filters = JSON.parse(localStorage.getItem("ArtyCoverageFilter"));
            if (filters === null) {
                filters = [];
            }
            filters.push({ name: filterName, value: filterValue });
            localStorage.setItem("ArtyCoverageFilter", JSON.stringify(filters));
            setOpen(false);
        };
        return (
            <div>
                <Button
                    className="filter-saver"
                    variant="outlined"
                    onClick={handleClickOpen}
                >
                    Spara
                    <br />
                    sökning
                </Button>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Spara sökning</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="filterName"
                            label="Sökning"
                            type="text"
                            fullWidth
                            value={filterName}
                            onChange={(event) => {
                                setFilterName(event.target.value);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Avbryt</Button>
                        <Button onClick={handleSave}>Spara</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    const SortingSelector = () => {
        return (
            <FormControl className="sort-filter">
                <InputLabel>Sortering</InputLabel>
                <Select
                    label={"Sortering"}
                    value={sortOrder}
                    onChange={(event) => setSortOrder(event.target.value)}
                    inputProps={{
                        name: "sortOrder",
                        id: "sortOrder",
                    }}
                >
                    <MenuItem value={"RELEVANCY"}>Relevant för dig</MenuItem>
                    <MenuItem value={"PUBLISHED_TIME"}>Datum</MenuItem>
                    <MenuItem value={"STRING_MATCH"}>Relevans</MenuItem>
                </Select>
            </FormControl>
        );
    };

    const SearchField = () => {
        return (
            <TextField
                className="search-bar"
                id="outlined-search"
                type="search"
                label="Sök"
                value={searchPhrase}
                onChange={(event) => {
                    setSearchPhrase(event.target.value);
                }}
                fullWidth
            />
        );
    };

    const SearchType = () => {
        return (
            <FormControlLabel
                className={"advanced-search"}
                xs={12}
                checked={advancedSearch}
                control={
                    <Switch
                        color="primary"
                        onChange={(event) => {
                            changeSearchType(event.target.checked);
                        }}
                    />
                }
                label="Avancerad sök"
                labelPlacement="start"
            />
        );
    };

    return (
        <>
            <Grid
                sx={{ display: advancedSearch ? "none" : "flex" }}
                className="protocol-filter"
            >
                <div className="protocol-filter-inner plain" style={{ paddingTop: '30px', paddingBottom: '15px' }}>
                    <Stack
                        spacing={8}
                        className="protocol-filter-search-wrapper"
                        direction="row"
                    >
                        <Stack spacing={2} className="protocol-filter-search" >
                            {SearchField()}
                            <BannerComponent />

                        </Stack>
                    </Stack>
                        
                    <Stack spacing={2} className="coverage-filter-saver">
                        {SearchType({ className: "advanced-search" })}
                        <SaveFilter />
                        <LoadFilter />
                    </Stack>
                </div>
            </Grid>

            <Grid
                sx={{ display: advancedSearch ? "flex" : "none" }}
                className="protocol-filter"
            >
                <div className="protocol-filter-inner">
                    <Stack
                        spacing={8}
                        className="protocol-filter-search-wrapper"
                        direction="row"
                    >
                        <Stack spacing={2} className="protocol-filter-search">
                            <Stack className="protocol-filter-upper" direction="row">
                                {DatePicker()}
                                {SourceSelector()}
                            </Stack>

                            <Stack className="protocol-filter-lower" direction="row">
                                {SearchField()}
                                {SortingSelector()}
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack spacing={2} className="coverage-filter-saver-advanced">
                        {SearchType({ className: "advanced-search" })}
                        <SaveFilter />
                        <LoadFilter />

                    </Stack>

                </div>
            </Grid>


        </>
    );

    function queryBuilder(startDate, endDate, searchPhrase, sortOrder, sourceSelector) {
        const searchConfig = {
            query: {
                type: sourceSelector,
                text: searchPhrase,
                date: {
                    to: endDate,
                    from: startDate,
                },
            },
            orderBy: {
                field: sortOrder,
                order: "DESC",
            },
        };
        setFilterValue(searchConfig);
        if (sourceSelector === "ALL") {
            delete searchConfig["query"]["type"];
        }

        return searchConfig;
    }
};
