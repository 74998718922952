import React, { useEffect, useState } from "react";

import { CoverageFilter } from "../components/Coverage/CoverageFilter";
import { CoverageList } from "../components/Coverage/CoverageList";

function Spinner(){
    return <span className="fa fa-circle-o-notch fa-spin"></span>
}

export const ArtyCoverageView = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    function setData(items) {
        setItems(items);
    }

    useEffect(() => {
        setData(items);
    }, [items]);

    return (
        <section className="arty-list-view" id="arty-list-view">
            <CoverageFilter setData={setData} onLoading={setLoading} />
            {!loading && <CoverageList items={items} />}
            {loading && 
            <div style={{"width": "100%", display:"flex"}}>
                <div style={{"margin": "auto"}}>
                    <p style={{ textAlign: "center", padding: "60px 0" }}>
                        <b>Letar efter artiklar <Spinner></Spinner></b>
                    </p>
                </div>
            </div>
            }
        </section>
    );
};
