import "../notif.css";

import React from "react";

export {notif};

const State = {
    callback: null,
}

export default class extends React.Component {
    state = {
        text: null
    };

    componentDidMount() {
        State.callback = this.handleNotif;
    }


    render() {
        if (this.state.text === null) {
            return null;
        }

        return (
            <section className="arty-notif-dialog">
                {this.state.text}
            </section>
        );
    }

    // Handlers

    handleNotif = (text) => {
        setTimeout(() => {
            this.setState({ text: null });
        }, 2000);

        this.setState({ text });
    }
}

function notif(text) {
    if (typeof State.callback === "function") {
        State.callback(text);
    }
}
