import React from "react";
import * as ReactRouter from "react-router-dom"
import * as ArtyApi from "../../ArtyApi.js";
import {    
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";

export const LogoutButton = (props) => {
    const [endSession, setEndSession] = React.useState(false);
    const LougoutSVG = "/images/logoutinv.svg";
    
    const handleLogout = event => {
        event.preventDefault();
        ArtyApi.destroy();
        setEndSession(true);
    }

    if (endSession) {
        return <ReactRouter.Redirect to="/login" />;
    }

    return (
        <ListItem className="list-item" disablePadding>
            <ListItemButton onClick={handleLogout}>
                <ListItemIcon sx={{ width: "15px", minWidth: "15px" }}>
                    <img src={LougoutSVG} width={15} height={15} alt={"Logga ut"} />
                </ListItemIcon>
                <ListItemText className="policy-list-item-text" disableTypography>
                    Logga ut
                </ListItemText>
            </ListItemButton>
        </ListItem>
    );
}