import "../landingView.css";

import React from "react";

import * as ReactRouter from "react-router-dom";

import * as ArtyApi from "../ArtyApi.js";

export default class ArtyLandingView extends React.Component {
    render() {
        if (ArtyApi.isAuthorized()) {
            return <ReactRouter.Redirect to="/app/" />
        }

        return (
            <div className="landing-page">
                <header className="secondary-font">
                    <ul>
                        <li id="logo">ARTY</li>
                        <li className="login">
                            <ReactRouter.Link to="/login/">
                                Logga in
                            </ReactRouter.Link>
                        </li>
                    </ul>
                </header>

                <main>
                    <section id="hero">
                        {/* <div className="inner">
                        </div> */}
                    </section>

                    <section className="features">
                        <div className="inner">
                            
                            <div>
                                <h2>Det här är ARTY</h2>
                                <p>Reform Society har tagit fram ett innovativt bevakningsverktyg, ARTY. Verktyget är en digital omvärldsbevakare som bygger på en AI-styrd rekommendationsmotor som uppdaterar vår webbapplikation med relevant och korrekt information varje halvtimme från pålitliga källor inom media, myndighetsbeslut och sociala medier.</p>
                            </div>
                            <div>
                                <h2>Varför ARTY</h2>
                                <p>ARTY riktar sig främst till beslutsfattare, kommunikationschefer och personer i ledande positioner inom läkemedelsbranschen, som behöver ett snabbt och lättillgängligt hjälpmedel för att hålla sig uppdaterade om utvecklingen inom sitt professionella ämnesområde.</p>
                            </div>
                            <div>
                                <h2>Så fungerar ARTY</h2>
                                <p>Källorna som vår AI-styrda rekommendationsmotor hänvisar till är noga utvalda och granskade av områdesexperter på Reform Society, vartefter ARTY fortsätter anpassningen av rekommendationerna efter användarens precisa informations-, fakta- och nyhetsbehov.</p>
                            </div>
                            <div>
                                <h2>Mer om ARTY</h2>
                                <p>Kontakta Birgitta Pagot på <a href="tel:+46704222711">070-422 27 11</a> eller <a href="mailto:birgitta.pagot@reformklubben.com">birgitta.pagot@reformklubben.com</a> för att komma igång med er unika omvärldsbevakning!</p>
                            </div>
                        </div>
                    </section>

                    {/* <section className="features light">
                        <div className="inner"></div>
                    </section> */}

                    <footer className="secondary-font">
                        <ul>
                            <li>© 2020 Artifical Minds AB</li>
                        </ul>
                    </footer>
                </main>
            </div>
        );
    }
}
