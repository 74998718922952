import React, { useState } from 'react';
import * as ReactRouter from "react-router-dom";

import * as ArtyApi from "../../ArtyApi.js";

export const DestroySessionForm = (props) => {
    const [endSession, setEndSession] = useState(false);

    const handleSubmit = event => {
        event.preventDefault();
        ArtyApi.destroy();
        setEndSession(true);
    }

    function component() {
        if (endSession) {
            return <ReactRouter.Redirect to="/login" />;
        }

        return (
            <form onSubmit={e => handleSubmit(e)} className="input-form" >
                <fieldset>
                    <legend>Logga ut från din session</legend>
                    <button type="submit">Logga ut</button>
                </fieldset>
            </form >
        );
    }

    return component();
}   
