import React from "react";
import ReactInfiniteScroll from "react-infinite-scroll-component";
import "./ProtocolFilter.css";

import ArtyArticle from "./ArtyArticle";

import * as ArtyApi from "../ArtyApi.js";
import { ProtocolFilter } from "./ProtocolFilter";

export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: props.filter,
            items: [],
            highlightPhrase: [],
            hasMore: true,
            listOffset: 0,
            listSize: 10,
            loading: true,
            expanded: props.expanded,
        };
    }

    async componentDidMount() {
        await this.fetchSources();
    }

    // fetchSources = async () => {
    //     let sources = await ArtyApi.getSources(0, 10, false);
    //     if (sources.data.length === 0) sources = await ArtyApi.getSources(0, 10, true);

    //     this.setState({ items: sources.data });
    // };

    updateItems = async (searchConfig, flush) => {
        const transformedConfig = {
            query: {
                from: flush ? 0 : this.state.listOffset,
                ...searchConfig.query,
            },
            orderBy: searchConfig.orderBy,
        };
        const protocolList = await ArtyApi.genericSearch(transformedConfig);
        console.log(protocolList.data.length < 10 ? false : true);
        this.setState({
            highlightPhrase: [searchConfig.query.text].concat(
                searchConfig.query.text.split(" ")
            ),
            filter: searchConfig,
            hasMore: protocolList.data.length < 10 ? false : true,

            items: flush ? protocolList.data : this.state.items.concat(protocolList.data),
        });
    };

    fetchMoreItems = () => {
        const newOffset = this.state.listOffset + 10;
        this.setState({
            listOffset: newOffset,
        });
        this.updateItems(this.state.filter, false);
    };

    render() {
        return (
            <section className="arty-list-view" id="arty-list-view">
                <div className="article-list">
                    <ProtocolFilter filterUpdated={this.updateItems}></ProtocolFilter>

                    <ReactInfiniteScroll
                        className="infinite-scroll"
                        scrollThreshold="200px"
                        dataLength={this.state.items.length}
                        hasMore={this.state.hasMore}
                        next={() => {
                            this.fetchMoreItems();
                        }}
                        loader={<h4 className="loading-msg">Laddar fler artiklar...</h4>}
                        endMessage={
                            <p style={{ textAlign: "center", padding: "60px 0" }}>
                                <b>Inga fler artiklar i listan</b>
                            </p>
                        }
                        scrollableTarget="arty-list-view"
                    >
                        {this.state.items.map((item) => {
                            return (
                                <ArtyArticle
                                    highlightPhrase={this.state.highlightPhrase}
                                    key={
                                        item.id + (this?.state?.highlightPhrase[0] ?? "")
                                    }
                                    item={item}
                                    expanded={this.state.expanded}
                                />
                            );
                        })}
                    </ReactInfiniteScroll>
                </div>
            </section>
        );
    }
}
