import "../shellView.css";

import React from "react";

import * as ArtyApi from "../ArtyApi.js";
import * as ArtySession from "../ArtySession.js";
import * as ReactRouter from "react-router-dom";

import { ArtyCoverageView } from "./ArtyCoverageView";
import ArtyMediaView from "./ArtyMediaView";
import ArtyArticleView from "./ArtyArticleView";
import ArtyDecisionView from "./ArtyDecisionView";
import ArtyProfileView from "./ArtyProfileView";
import ArtyProtocolView from "./ArtyProtocolView.jsx";

export default class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            session: null,
            sessionError: false,
            root: props.match.path,
        };
    }

    async componentDidMount() {
        const session = await ArtyApi.session();
        ArtySession.init(session);
        let licenses = session.data.user.organisations[0].licenses.map(
            (license) => license.name
        );
        licenses = licenses.concat(
            session.data.user.licenses.map((license) => license.name)
        );
        this.setState({
            session: session,
            licenses: licenses,
            sessionError: licenses !== undefined,
        });

        return session;
    }

    render() {
        return (
            <div id="arty-shell">
                {this.state.session?.ghostSession && (
                    <p style={{ backgroundColor: "red" }}>asdf</p>
                )}
                <Header session={this.state.session} />
                <Content>
                    <ReactRouter.Route exact path="/app/">
                        <ReactRouter.Redirect to="/app/media/" />
                    </ReactRouter.Route>
                    <ReactRouter.Route path="/app/media/">
                        <ArtyMediaView />
                    </ReactRouter.Route>
                    <ReactRouter.Route path="/app/social/">
                        <ReactRouter.Redirect to="/app/media/" />
                    </ReactRouter.Route>
                    <ReactRouter.Route path="/app/decision/">
                        <ArtyDecisionView />
                    </ReactRouter.Route>
                    <ReactRouter.Route path="/app/protocol/">
                        <ArtyProtocolView />
                    </ReactRouter.Route>
                    <ReactRouter.Route path="/app/coverage/">
                        <ArtyCoverageView />
                    </ReactRouter.Route>
                    <ReactRouter.Route path="/app/profile/" component={ArtyProfileView} />
                    <ReactRouter.Route
                        path="/app/article/:id/"
                        component={ArtyArticleView}
                    />
                </Content>
                <Footer
                    licenses={
                        this.state && this.state.licenses ? this.state.licenses : []
                    }
                />
            </div>
        );
    }
}

// Internals

function Header(props) {
    const name = `${props?.session?.data.user.firstName} ${props?.session?.data.user.lastName}`;

    return (
        <header className="shell-header">
            <ul>
                <li className="middle-column">
                    <ReactRouter.Link to="/app/">
                        <span className="arty-logo">{TitleText()}</span>
                    </ReactRouter.Link>
                </li>
                <li>
                    <ReactRouter.Link to="/app/profile/" title="Inställningar">
                        {props?.session?.data && (
                            <span className="user-settings">{name} &nbsp;</span>
                        )}
                        <i className="fa fa-cogs" />
                    </ReactRouter.Link>
                </li>
            </ul>
        </header>
    );
}

function Content(props) {
    return (
        <div className="shell-content" id="shell-content">
            {props.children}
        </div>
    );
}

function Footer(props) {
    return (
        <footer className="shell-footer">
            <ul>
                <li>
                    <ReactRouter.NavLink to="/app/media/">
                        <i className="fa fa-id-card-o" aria-hidden="true" />
                        <span>Media</span>
                    </ReactRouter.NavLink>
                </li>

                <li>
                    <ReactRouter.NavLink to="/app/decision/">
                        <i className="fa fa-archive" aria-hidden="true" />
                        <span>Beslut</span>
                    </ReactRouter.NavLink>
                </li>
                <li className={props.licenses.includes("PROTOCOL") ? "" : "disabled-tab"}>
                    <ReactRouter.NavLink to="/app/protocol/">
                        <i className="fa fa-file-pdf-o" aria-hidden="true" />
                        <span>Protokoll</span>
                    </ReactRouter.NavLink>
                </li>
                <li className={props.licenses.includes("POLICY") ? "" : "disabled-tab"}>
                    <ReactRouter.NavLink to="/policy/">
                        <i className="fa fa-shield" aria-hidden="true" />
                        <span>Policy</span>
                    </ReactRouter.NavLink>
                </li>
                <li>
                    <ReactRouter.NavLink to="/app/coverage/">
                        <i className="fa fa-search" aria-hidden="true" />
                        <span>Sök</span>
                    </ReactRouter.NavLink>
                </li>
            </ul>
        </footer>
    );
}

function TitleText() {
    return "ARTY";
}
