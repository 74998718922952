import "../profileView.css";

import React from "react";

import * as ArtyApi from "../ArtyApi";

import { SourcesForm } from "../components/Profile/SourcesForm";
import { ChangePasswordForm } from "../components/Profile/ChangePasswordForm";
import { DestroySessionForm } from "../components/Profile/DestroySessionForm";

export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            crawlerSources: undefined,
            socialSources: undefined,
            userId: "",
        };
    }

    async componentDidMount() {
        const profile = await ArtyApi.profile();
        const session = await ArtyApi.session();
        const mediaSources = profile.data.filter((source) => source.type === "ARTICLE");
        const socialSources = profile.data.filter((source) => source.type === "SOCIAL");

        this.setState({
            crawlerSources: mediaSources,
            socialSources: socialSources,
            userId: session.data.user.id,
            firstname: session.data.user.firstName,
            lastname: session.data.user.lastName,
        });
    }

    render() {
        const name = `${this.state.firstname ?? ""} ${this.state.lastname ?? ""}`;

        return (
            <section className="arty-profile-view">
                <div className="inner">
                    <h2>Hej {name}</h2>
                    <SourcesForm
                        items={this.state.crawlerSources}
                        group="crawler"
                        title="Webbkällor"
                    />
                    {/* <SourcesForm
                        items={this.state.socialSources}
                        group="social"
                        title="Sociala källor"
                    /> */}
                    {/* <SourcesForm
                        items={this.state.settings.coverageTerms}
                        group="coverage"
                        title="Nyckelord för bevakning"
                    /> */}
                    <ChangePasswordForm id={this.state.userId} />
                    <DestroySessionForm />
                    <br></br>
                    <br></br>
                    <br></br>
                    <fieldset>
                        <legend>
                            Version: {process.env.REACT_APP_VERSION ?? "1.0.0"}
                        </legend>
                    </fieldset>
                </div>
            </section>
        );
    }
}
