import React, { useState, useEffect } from "react";

import * as ReactRouter from "react-router-dom";

import { LoginForm } from "../components/Login/LoginForm";

export const ArtyLoginView = (props) => {
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const [redirectTarget, setRedirectTarget] = useState("/app/media");
    const [from, setFrom] = useState({});

    useEffect(() => {
        setFrom(props.location.state || redirectTarget);
    }, [props.location.state, redirectTarget]);

    return redirectToReferrer ? (
        <ReactRouter.Redirect to={from} />
    ) : (
        <LoginForm
            redirectToReferrer={setRedirectToReferrer}
            setRedirectTarget={setRedirectTarget}
            redirectTarget={redirectTarget}
        />
    );
};
