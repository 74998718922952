import React from "react";

import ReactInfiniteScroll from "react-infinite-scroll-component";

import ArtyArticle from "./ArtyArticle";
import ArtyPromoteList from "./ArtyPromoteList";

import * as ArtyApi from "../ArtyApi.js";

export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: props.filter,
            items: [],
            promotedItems: [],
            hasMore: true,
            listOffset: 0,
            listSize: 10,
            expanded: props.expanded,
        };
    }

    componentDidMount() {
        this.fetchPromoted();
        this.fetchMoreData([], this.state.filter, 0, this.state.listSize);
    }

    fetchPromoted = async () => {
        const promtedArticles = await ArtyApi.promoteList("ARTICLE");
        this.setState({ promotedItems: promtedArticles });
    };

    fetchMoreData = async (
        items = this.state.items,
        filter = this.state.filter,
        offset = this.state.listOffset,
        size = this.state.listSize
    ) => {
        const articles = await ArtyApi.articleList(filter, offset, size);
        this.setState({
            items: items.concat(articles),
            listOffset: offset + size,
            hasMore: false,
        });
    };

    render() {
        return (
            <section className="arty-list-view" id="arty-list-view">
                <div className="article-list">
                    <ArtyPromoteList
                        items={this.state.promotedItems}
                        group={this.state.filter}
                    />
                    <ReactInfiniteScroll
                        dataLength={this.state.items.length}
                        next={this.fetchMoreData}
                        hasMore={this.state.hasMore}
                        loader={<h4 className="loading-msg">Laddar fler artiklar...</h4>}
                        endMessage={
                            <p style={{ textAlign: "center", padding: "60px 0" }}>
                                <b>Inga fler artiklar i listan</b>
                            </p>
                        }
                        scrollableTarget="arty-list-view"
                    >
                        {this.state.items.map((item) => {
                            return (
                                <ArtyArticle
                                    revalidate={async () => {
                                        await this.fetchPromoted();
                                    }}
                                    key={item.id}
                                    item={item}
                                    expanded={this.state.expanded}
                                />
                            );
                        })}
                    </ReactInfiniteScroll>
                </div>
            </section>
        );
    }
}
