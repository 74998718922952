export { init };
export { meta };
export { login };
export { isAuthorized };
export { username };
export { session };
export { profile };
export { profileToggleSetting };
export { article };
export { articleList };
export { articleUpvote };
export { articleDownvote };
export { articlePromote };
export { articleTrackInteraction };
export { promoteList };
export { decisionList };
export { decisionUnseenList };
export { mediaList };
export { mediaUnseenList };
export { socialList };
export { getSharingUrl };
export { changePassword };
export { destroy };
export { policyArticleById };
export { policyArticles };
export { nPolicyArticles };
export { genericSearch };
export { getProtocols as getSources };

const LOCAL_USER_KEY = "ARTY_USER_TOKEN";
const LOCAL_ACCESS_KEY = "ARTY_ACCESS_TOKEN";

const State = {
    username: null,
    baseUrl: null,
    accessToken: null,
};

function init(baseUrl = "https://api.staging.artyapp.io/v1/") {
    State.baseUrl = baseUrl;
    State.username = window.localStorage.getItem(LOCAL_USER_KEY) || null;
    State.accessToken = window.localStorage.getItem(LOCAL_ACCESS_KEY) || null;
}

function toCamel(object) {
    if (object === null) return;
    const newObject = {};
    const keys = Object.keys(object);

    for (const key of keys) {
        const value = object[key];
        const keyInCamelCase = key
            .split("_")
            .map((v, i) => (i !== 0 ? v.slice(0, 1).toUpperCase() + v.slice(1) : v))
            .join("");

        if (typeof value === "object" && Array.isArray(value) && key !== "snippets") {
            const newValues = value.map((v) => toCamel(v));
            newObject[keyInCamelCase] = newValues;
        } else if (typeof value === "object" && !Array.isArray(value)) {
            newObject[keyInCamelCase] = toCamel(value);
        } else {
            newObject[keyInCamelCase] = value;
        }
    }

    return newObject;
}

async function meta() {
    return {
        version: "abc",
    };

}



async function login(username, password) {
    const init = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ email: username, password }),
    };

    const result = await fetch(`${State.baseUrl}/users/auth/login`, init);

    if (result.ok === false) {
        throw new Error("Wrong username or password");
    }

    const body = await result.json();
    window.localStorage.setItem("session", JSON.stringify(body.data));

    return true;
}

function isAuthorized(cb) {
    return (
        window.localStorage.getItem("session") ??
        window.localStorage.getItem("ARTY_ACCESS_TOKEN")
    );
}

function username() {
    return State.username;
}

async function session() {
    const res = await internalGet("/users/auth/session/");
    window.localStorage.setItem("session", JSON.stringify(res.data));
    return res;
}



async function profile() {
    const userId = JSON.parse(window.localStorage.getItem("session")).user.id;
    return await internalGet(`/users/profile/sources/?id=${userId}`);
    // return internalGet(`/users/profile/sources`);
}

async function profileToggleSetting(source, id) {
    return internalPut(`/users/profile/sources/${id}`, source);
}

async function article(id) {
    return internalGet(`/documents/${id}`);
}

async function articleList(group, offset = 0, size = 5) {
    return [];
}

async function promoteList(group, offset = 0, size = 5) {
    const session = JSON.parse(window.localStorage.getItem("session"));

    return internalGet(
        `/documents/organisations/${session.user.organisations[0].id}?limit=5&type=${group}`
    ).then((res) => {
        return res.data.promoted;
    });
}

async function mediaList(offset = 0, size = 5) {
    return internalGet(
        `/documents/search/?skip=${offset}&limit=${size}&seen=true&type=ARTICLE`
    );
}

async function mediaUnseenList(offset = 0, size = 5) {
    return internalGet(
        `/documents/search/?skip=${offset}&limit=${size}&seen=false&type=ARTICLE`
    );
}

async function socialList(offset = 0, size = 10, seen = false) {
    return internalGet(`/documents/search/?type=SOCIAL&skip=${offset}&limit=${size}`);
}

async function decisionList(offset = 0, size = 5) {
    return internalGet(`/documents/search/?type=DECISION&seen=true&skip=${offset}`);
}

async function decisionUnseenList(offset = 0, size = 5) {
    return internalGet(`/documents/search/?type=DECISION&seen=false&skip=${offset}`);
    // return internalGet(`/api/decision/?offset=${offset}&size=${size}&filter=unseen`);
}

async function policyArticleById(id) {
    return [];
    //  return internalGet(`${id}/`);
}

async function policyArticles() {
    return internalGet(`https://app.staging.artyapp.io/d/policies.json`, true);
}

async function nPolicyArticles(offset = 0, size = 5) {
    return [];
    // return internalGet(`/api/policy/articles/?from=${offset}&size=${size}`);
}

async function articleUpvote(id) {
    return articleTrackInteraction(id, "LIKED");
}

async function articleDownvote(id) {
    return articleTrackInteraction(id, "UNLIKED");
}

async function articlePromote(id) {
    return articleTrackInteraction(id, "PROMOTED");
}

async function articleTrackInteraction(id, type) {
    const session = JSON.parse(window.localStorage.getItem("session"));

    const body = {
        user_account: {
            id: session.user.id,
        },
        document: {
            id: id,
        },
        interaction: type,
    };

    return internalPost("/documents/interactions", body);

    // return internalPut(`/api/article/${id}/interaction/`, { type });
}

async function changePassword(password, id) {
    return internalPut(`/users/auth/password/${id}`, { password });
}

async function getSharingUrl(url, snippet) {
    if (State.baseUrl.includes("localhost")) {
        return snippet
            ? `http://localhost:3002/share/?t=protocol&sn=${snippet}&u=${url}`
            : `http://localhost:3002/share/?t=protocol&u=${url}`;
    }

    return snippet
        ? `${State.baseUrl}/share/?t=protocol&sn=${snippet}&u=${url}`
        : `${State.baseUrl}/share/?t=protocol&u=${url}`;
}

async function destroy() {
    State.username = null;
    State.accessToken = null;
    await internalGet("/users/auth/logout");
    window.localStorage.removeItem(LOCAL_USER_KEY);
    window.localStorage.removeItem(LOCAL_ACCESS_KEY);
}

async function genericSearch(query) {
    console.log(query);
    return internalPost("/documents/search", query);
}

async function getProtocols(offset = 0, size = 10, seen = false) {
    try {
        return await internalGet(
            `/documents/search/?type=PROTOCOL&skip=${offset}&limit=${size}`
        );
    } catch (e) { }
}

async function internalGet(uriPart, fullUrl = false) {
    const init = {
        method: "GET",
        credentials: "include",
        mode: "cors",
    };

    if (localStorage.getItem("ARTY_ACCESS_TOKEN")) {
        init.headers = {
            authorization: `Bearer ${localStorage.getItem("ARTY_ACCESS_TOKEN")}`,
        };
    }

    const result = await fetch(!fullUrl ? `${State.baseUrl}${uriPart}` : uriPart, init);

    if (!result.ok) {
        throw new Error("Something went with the API");
    }

    if (result.headers.get("content-type").includes("application/json")) {
        const json = await result.json();
        return toCamel(json);
    }

    return await result.text();
}

async function internalPut(uriPart, body = null) {
    try {
        const init = {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        };
        const result = await fetch(`${State.baseUrl}${uriPart}`, init);

        if (result.headers.get("content-type").includes("application/json")) {
            const json = await result.json();

            return toCamel(json);
        }

        return await result.text();
    } catch (e) {
        throw new Error(`Error : ${e}`);
    }
}

async function internalPost(uriPart, body) {
    try {
        const init = {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        };
        const result = await fetch(`${State.baseUrl}${uriPart}`, init);

        if (result.headers.get("content-type").includes("application/json")) {
            const json = await result.json();
            return toCamel(json);
        }

        return await result.text();
    } catch (e) {
        throw new Error(`Error : ${e}`);
    }
}
